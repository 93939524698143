export default {
  methods: {
    copyToClipboard(value) {
      navigator.clipboard
        .writeText(value)
        .then(() => {
          this.$notify('Copied to clipboard')
        })
        .catch((err) => {
          this.$notify({
            group: 'danger',
            title: 'Warning',
            text: 'Unable to copy to clipboard'
          })
        })
    }
  }
}
