<template>
  <tbody v-if="$wait.is('response')">
    <tr>
      <td
        v-for="(column, index) in columns" 
        :key="index"
      >
        <content-placeholder />
      </td>
    </tr>
  </tbody>
  <tbody 
    v-else
    data-test-id="history-table-companion"
  >
    <tr
      v-for="(item, index) in rows"
      :key="index"
    >
      <td>
        <router-link
          v-if="item.request && item.request.vrm"
          :to="getLink(item)"
          class="button is-size-7-mobile button-extras"
          @click.native="track('vrm')"
        >
          {{ item.request.vrm }}
        </router-link>
      </td>
      <td
        class="is-hidden-touch" 
        v-if="hasCustomerReference"
      >
        <span
          v-if="item && item.request.customerReference"
          class="refTag customerReferenceTag"
          @click="copyToClipboard(item.request.customerReference)"
        >
          {{ item.request.customerReference }}
        </span>
        <span 
          v-else 
          class="refTag customerReferenceTagOff" 
        >No reference</span>
      </td>
      <td class="is-hidden-touch">
        <router-link
          :to="getLink(item)"
          class="is-size-6 cell-vehicle-description cell-values-decoration"
          @click.native="track('description')"
        >
          {{ truncate(item.data.description) }}
        </router-link>
      </td>
      <td class="is-hidden-touch">
        <span class="cell-trade-value">
          {{ pounds(item.data.remarket) || '-' }}
        </span>
      </td>
      <td class="is-hidden-touch">
        <span class="cell-retail-value">
          {{ pounds(item.data.retail) || '-' }}
        </span>
      </td>
      <td class="is-hidden-touch">
        <span class="cell-mileage">{{ number(item.data.mileage.value) }}</span>
        <span
          v-if="item.data.mileage.isEstimated"
          class="cell-mileage"
        >
          ~
        </span>
      </td>
      <td>
        <span class="cell-date">{{ formatDate(item.createdAt) }}</span>
      </td>
      <td
        v-if="depth !== 'user' && item.user"
        class="is-hidden-touch"
      >
        {{ item.user.name }}
      </td>
      <td
        v-if="depth === 'org' && item.branch" 
        class="is-hidden-touch"
      >
        {{ item.branch.name }}
      </td>
      <td>
        <div class="field is-grouped">
          <p class="control mr-3">
            <button
              v-tippy
              :class="{ 'is-loading': $wait.is(`loading-${index}`) }"
              :disabled="$wait.is(`loading-${index}`)"
              class="button is-size-7-mobile is-soft is-outlined"
              title="Recalculate value"
              @click="value(item, index)"
              data-test-id="companion-table-revalue"
            >
              <span class="icon">
                <i class="fal fa-sync-alt" />
              </span>
            </button>
          </p>
          <p class="control">
            <button
              v-tippy
              :class="{ 'is-loading': $wait.is(`loading-${index}`) }"
              :disabled="$wait.is(`loading-${index}`)"
              title="Check vehicle"
              v-if="hasAutoCheck"
              class="button is-size-7-mobile is-soft is-outlined"
              @click="buyCheck(item, index)"
              data-test-id="companion-table-autocheck"
            >
              <span class="icon">
                <i class="fal fa-check" />
              </span>
            </button>
          </p>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import * as companion from 'modules/companion/services'
import * as autocheck from 'modules/autocheck/services'
import truncate from 'truncate'
import { formatDate } from '@/utils/date.utils.ts'
import clipboard from '@/mixins/clipboard'
import { formatMoney, formatNumber } from 'accounting'
import { mapGetters } from 'vuex'
export default {
  name: 'AccountHistoryCompanion',
  mixins: [clipboard],
  components: {
    contentPlaceholder: () => import('core/components/ContentPlaceholder')
  },
  props: {
    depth: {
      type: String,
      required: false,
      default: () => 'user'
    },
    preview: {
      type: Boolean,
      default: () => false
    },
    rows: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('auth', [
      'isCompanionUser',
      'hasAutoCheck',
      'hasCustomerReference'
    ])
  },

  methods: {
    formatDate,
    track(source) {
      this.$mxp.track(
        `${this.depth}_history_companion_${source}_view_companion_response`
      )
    },
    getLink(item) {
      return { name: 'companion', params: { uuid: item.uuid } }
    },
    truncate(value) {
      return truncate(value, 45)
    },
    async buyCheck(item, index) {
      this.$mxp.track(`${this.depth}_history_companion_open_autocheck_modal`)
      this.$wait.start(`loading-${index}`)
      const value = item.request ? item.request.vrm : item.vrm
      try {
        const data = await autocheck.precheck({
          value,
          type: 'vrm'
        })
        this.$modal.open('autocheck/AutoCheckOptions', {
          vrm: value,
          vin: data.vin,
          description: autocheck.buildPreDescription(data),
          mileage: data.mileage
        })
      } catch (err) {
        this.$mxp.track(
          `${this.depth}_history_companion_open_autocheck_modal_failed`
        )
        const msg = err?.response?.data?.error?.message
        this.$notify(msg || 'Currently unable to perform a check')
      } finally {
        this.$wait.end(`loading-${index}`)
      }
    },
    async value(item, index) {
      if (!this.isCompanionUser) {
        this.$mxp.track(`${this.depth}_history_companion_companion_upsell`)
        this.toggleUpsell()
      } else {
        this.$mxp.track(`${this.depth}_history_companion_companion_revaluation`)
        this.$wait.start(`loading-${index}`)
        const vrm = item.vrm ? item.vrm : item.request.vrm
        await companion.value({ vrm })
        this.$wait.end(`loading-${index}`)
      }
    },
    toggleUpsell() {
      this.$modal.open('companion/UpsellCompanion')
    },
    pounds(value) {
      return formatMoney(value, '£', 0)
    },
    percentage(value) {
      return `${formatNumber(value, 2)}%`
    },
    number(value) {
      return `${formatNumber(value)}`
    }
  }
}
</script>
<style lang="sass" scoped>
.refTag 
  display: block
  border-radius: 4px
  font-size: 0.85rem
  padding: 0.5em 1.25em
  text-align: center
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  max-width: 8rem
  width: 100%

.customerReferenceTag 
  background-color: #edf6ff
  border: 1px solid #b3d7ff
  color: #19a5cc
  cursor: pointer

.customerReferenceTagOff 
  background-color: #f0f0f0
  border: 1px solid #d1d1d1
  color: rgb(95, 95, 95)

.cell-vehicle-description
  font-weight: $history-table-cell-font-weight
  color: $history-table-cell-text-color
.cell-trade-value, .cell-retail-value
  font-weight: $history-table-cell-font-weight-bold
  color: $history-table-cell-text-color
.cell-mileage,.cell-date
  font-weight: $history-table-cell-font-weight
  color: $history-table-cell-text-color-alt
.cell-values-decoration
  text-decoration: $history-table-cell-decorations
</style>
